import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import SignaturePad from "react-signature-canvas";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Container,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Button,
  HStack,
  Flex,
  Text,
  Spinner,
  Icon,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
} from "@chakra-ui/react";
import { BsXCircle } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";


const schema = yup.object().shape({
  organization: yup.string().required("Organization is required"),
  contact: yup.string().required("Contact is required"),
  phone: yup
    .string()
    .required()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Invalid phone number"
    ),
  email: yup
    .string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  address: yup.string().required("Address is required"),
  event: yup.string().required("Event is required"),
  applicantSignature: yup
    .string()
    .required("Signature is required.")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
  date: yup.string().required("Date is required"),
  eventLocation: yup.string().required("Event location is required"),
  eventDescription: yup.string().required("Event description is required"),
  preferredLocations: yup.string().required("Preferred locations is required"),
  dateFrom: yup.string().required('Date from is required'),
  dateTo: yup.string().required('Date to is required'),
  totalNumberBanners: yup.string().required('Total number of banners is required'),
});


function RequiredSpan() {
  return <span style={{ color: "#bf1650" }}>*</span>;
}

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
};

const BannerForm = () => {
  const defaultValues = {
    licenseType: "",
    imgUrl: "",
  };

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const watchData = watch();
  
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const formRef = useRef(null);

  const FormSuccess = () => (
    <Box my="20">
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p="4"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Application submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
        Thank you for submitting your application. We will be in touch with you shortly.<br /><br />Please check your email for a copy of your application for your records.
  </AlertDescription>
    </Alert>
  </Box>
   )

  const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);
    
    // convert signature to blob
    const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");

    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };

    // Convert license array to object - parsing function only returns the last value
    {watchData.underSignedTaxes && (
      convertArrayToObject(watchData.underSignedTaxes, "Taxes")
    )}

    {watchData.underSignedTaxes && (
      formData.append('Taxes', JSON.stringify(watchData.underSignedTaxes))
    )}
    
    

    // Use console.log() to see the data that is being sent to the server
    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-banner-application', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true)
                    setProcessing(false)
                    reset()
                    scrollTop()
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};



  {
    /* Signature Pad */
  }
  let sigCanvas = useRef({});

  const formatIntoPng = () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current.toDataURL();
      return dataURL;
    }
  };

  const clear = () => sigCanvas.current.clear();

  return (
    <Container maxW="container.xl">
      <Box id="theForm">
        {succeeded ? (
          <FormSuccess />
        ) : (
          <>

            <form onSubmit={handleSubmit(sendMailgunFormData)} ref={formRef}>
              <Flex w="100%" flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Organization<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("organization")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.organization && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.organization?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w={{ base: "100%", md: "50%" }} pl={{ base: 0, md: "4" }}>
                  <FormLabel>Telephone<RequiredSpan /></FormLabel>
                  <Input type="tel" {...register("phone")} autoComplete="on" />
                  <p style={errorStyles}>
                    {errors.phone && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.phone?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Flex w="100%" flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Contact<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("contact")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.contact && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.contact?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w={{ base: "100%", md: "50%" }} pl={{ base: 0, md: "4" }}>
                  <FormLabel>Email<RequiredSpan /></FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.email && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.email?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mb="8">
                <FormLabel>Address<RequiredSpan /></FormLabel>
                <Input
                  type="address"
                  {...register("address", { required: true })}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.address && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.address?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Flex w="100%" flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Event<RequiredSpan /></FormLabel>
                  <Input type="text" {...register("event")} autoComplete="on" />
                  <p style={errorStyles}>
                    {errors.event && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.event?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box pl={{ base: "0", md: 4 }} w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Date(s) of event<RequiredSpan /></FormLabel>
                  <Input
                    type="date"
                    {...register("date")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.date && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.date?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mb="8">
                <FormLabel>Event Location<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("eventLocation")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.eventLocation && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.eventLocation?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>
                  Please provide a brief description of your event<RequiredSpan />
                </FormLabel>
                <Textarea
                  {...register("eventDescription")}
                  autoComplete="on"
                ></Textarea>
                <p style={errorStyles}>
                  {errors.eventDescription && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.eventDescription?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Preferred locations<RequiredSpan /></FormLabel>
                <Textarea
                  {...register("preferredLocations")}
                  autoComplete="on"
                ></Textarea>
                <p style={errorStyles}>
                  {errors.preferredLocations && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.preferredLocations?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Text mb="4">Preferred banner display dates<RequiredSpan /></Text>

              <Flex w="100%" flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>From:</FormLabel>
                  <Input
                    type="date"
                    {...register("dateFrom")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.dateFrom && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.dateFrom?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box pl={{ base: "0", md: 4 }} w={{ base: "100%", md: "50%" }}>
                  <FormLabel>To:</FormLabel>
                  <Input
                    type="date"
                    {...register("dateTo")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.dateTo && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.dateTo?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mb="8">
                <FormLabel>Total number of banners<RequiredSpan /></FormLabel>
                <Input
                  type="number"
                  {...register("totalNumberBanners")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.totalNumberBanners && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.totalNumberBanners?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box border="1px" p="6" mb="8">
                <Text as="b">
                  Fees $100.00 for first banner, plus $25.00 per each additional
                  banner
                </Text>
              </Box>

              <Box mb="8">
                <Text mb="4">
                  A non-refundable, $25.00 fee is due upon approval. The balance is due twenty four (24) hours in advance of installation.
                </Text>

                <Text mb="4">
                  I understand that this application, as well as banner designs,
                  must be approved by the Driggs City Council, or its designee.
                  I agree to pay all fees as stipulated. I understand that it is
                  my responsibility to deliver the banners to City Hall. I also
                  agree to pick up my banners within 7 days of removal or pay
                  for storage of the banners. I agree to not hold The City of
                  Driggs responsible for any damage to my banners while they are
                  in their possession.
                </Text>

                <Text>
                  I have read and understand all of the information on this
                  application:
                </Text>
              </Box>

              
                <Box mb="8" w={{ base: "100%", md: "50%" }}>
                  <FormControl isInvalid={!!errors?.applicantSignature?.message}>
                    <FormLabel>
                      Signature of Applicant or Authorized Representative:
                    </FormLabel>
                    <Box>
                      <Controller
                        name="applicantSignature"
                        control={control}
                        render={({ field }) => (
                          <SignaturePad
                            ref={sigCanvas}
                            onEnd={() =>
                              field.onChange(
                                formatIntoPng(sigCanvas.current.toDataURL())
                              )
                            }
                            penColor="black"
                            canvasProps={{
                              style: {
                                border: "1px solid #e2e8f0",
                                borderRadius: "0.375rem",
                                width: "100%",
                                height: "200px",
                                backgroundColor: "white",
                              },
                            }}
                          />
                        )}
                      />

                      {errors.applicantSignature && (
                        <HStack spacing="5px" style={errorStyles}>
                          <VscWarning /> <Box>{errors.applicantSignature?.message}</Box>
                        </HStack>
                      )}
                    </Box>
                    <Box>
                      <input
                        type="hidden"
                        value={new Date()}
                        name="timestamp"
                        {...register("timestamp", { required: true })}
                      />
                    </Box>
                  </FormControl>
                  <Box my="2" textAlign="right">
                    <Button size="sm" onClick={clear} colorScheme="red">
                      <Icon as={BsXCircle} mr="2" />
                      Clear Signature
                    </Button>
                  </Box>
                </Box>
              

<Box my="6">
              <Button type="submit" textAlign="left" disabled={processing}>
                {processing ? (
                  <Spinner />
                ) : succeeded ? (
                  "Submitted!"
                ) : (
                  <>
                  Submit
                  </>
                )}
              </Button>
              {error && (
                  <div style={errorStyles} role="alert">
                    <HStack spacing="5px">
                      <VscWarning/><p className="card-error">{error}</p> 
                    </HStack>
                  </div>
                )}
            </Box>
            </form>
          </>
        )}
      </Box>
    </Container>
  );
};

export default BannerForm;
